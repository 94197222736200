import React, { useState } from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { Container, Row, Col } from 'react-bootstrap';
import moment from "moment/dist/moment";
import Header from "../Header/Header";
import HomeGetStart from "@Components/Home/HomeGetStart/HomeGetStart";
import AgentsTeam from "@Components/StaticPage/AgentsTeam/AgentsTeam";
import BreadcrumbPage from "@Components/Home/BreadcrumbPage/BreadcrumbPage";
import TeamDetailsBanner from "@Components/TeamDetailsBanner/TeamDetailsBanner";
import logoBlack from "../../images/logo-t.svg";

const BlogDetails = (props) => {
  const GET_TEAM_DETAILS = gql`
  query GetTeam($URL: String!){	
    teams(where:{URL: $URL}) {
      id
      imagetransforms
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
      Video_Url
      URL
      Publish
      Phone
      Name
      Email
      Designation
      Content
      Image {
        alternativeText
        url
      }
      offices {
        
        Office_Name
        Team_Content
        Teams {
          id
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
          Name
          Designation
          URL
          Image {
            url
            alternativeText
          }
          Video_Url
          
        }
      }
      Request_A_Viewing_CTA
  }
}
`;

  const { loading, error, data } = useQuery(GET_TEAM_DETAILS, {
    variables: { URL: props.slug }
  });
  let shareurl = '';

  if (typeof window !== "undefined") {
      shareurl = window.location.href ? window.location.href : '';
  }

  var names = [];
  data?.teams[0]?.offices[0]?.Teams?.forEach(function(obj) {
      if (obj?.URL !== props.slug){
        names.push(obj);
      } 
  });

// console.log(names)

// if (loading) return (
//   <section className={"loader-wrapper"}>
//     <div id="loader-wrapper">
//       <div id="loader" class="new-loader">
//         <div className="new-loader-anime"></div>
//         <img className="logo-white" src={logoBlack} className="loader-logo" alt="logo" />
//       </div>
//       <div class="loader-section section-left"></div>
//       <div class="loader-section section-right"></div>
//     </div>
//   </section>
// );

if (loading) return (
  <div className="static-news gql-loading-screen">
    <Container>
      <div className="gql-loading">
      <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
        </div>
    </Container>
  </div>
);

  return (
    <Layout popularSearch={"Common"} isBlack={true} className="blog-page" header={true}>
      {data?.teams.map((Page, i) => {
        return (
          <>
            <SEO  title={Page ? Page.Name+', '+Page.Designation+' | Property Experts ' : ''} description={Page ? 'Meet '+Page.Name+', '+Page.Designation+' in College and County. Get in touch with us today for more details about the property in Oxford and Thame' : ''}  />

            <Helmet
              bodyAttributes={{
                class: `templates-blog-details-template`
              }}
            />
            
              
              <TeamDetailsBanner data={Page} />
            {names?.length > 0 ?
            <AgentsTeam 
            name={Page.Name}
            office={"College and County"}
             content={Page.offices[0]?.Team_Content}
              teams={names}
               />
               : null}
            <HomeGetStart nobanner={true} />
            <div className="breadcrumb-other-page"><BreadcrumbPage Page={Page.Name} class="static" alias={'meet-our-team'} type="details-page"/></div>
          </>
        )
      })}
    </Layout>
  );
}



export default BlogDetails