import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Dropdown } from "react-bootstrap";
import Phone from "../../images/phone.svg"
import "./TeamDetailsBanner.scss";
import { TEAM_PAGE_URL, VALUATION_PAGE_URL } from "@Components/common/site/constants";
import ReactMarkdown from "react-markdown"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import ModelForm from "@Components/ModelForm/ModelForm";
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import AskusaQuestion from "@Components/AskusaQuestion/AskusaQuestion";
import RequestAViwing from "@Components/RequestAViewing/RequestAViewing";

import { inViewOptions } from '../../Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import NoImage from "../utils/noImage";

import {ImageModule} from "@Components/common/Image_Module"

export const uknumberformat = (x) => {
  if (!x) return x; 
  const currentValue = x.toString().replace(/[^\d]/g, '');
  return `${currentValue.slice(0, 5)} ${currentValue.slice(5, 11)}`; 
}
const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const TeamDetailsBanner = (props) => {

  const [videoIdmen, setVideoIdmen] = useState('');
  const [showVideomen, setShowVideomen] = useState(false);
  const playVideomen = (Embed_Video_URL) => {
    var videoid = getVideoId(Embed_Video_URL);
    setShowVideomen(true)
    setVideoIdmen(videoid.id)

  }
  const closePlaymen = () => {
    setShowVideomen(false)
    setVideoIdmen('')
  }
  const trackerVideomen = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }

  let processedImages = JSON.stringify({});
  if (props.data?.imagetransforms?.Image_Transforms) {
    processedImages = props.data.imagetransforms.Image_Transforms;
  }

  // console.log(props.data, "test");

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => {
        return (
          <motion.section
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <React.Fragment>
              <section className="team-details-banner">
                <Container>
                  <Row>
                    <Col lg={7}>
                      <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
                        <motion.div variants={titleVariants}>
                          <div className="back-button-wrap"><Link className="back-link" to={TEAM_PAGE_URL.alias}><span className="arrow-left-button d-none d-xl-block"><i className="arrow-banner-left"></i></span></Link>
                            <h1>{props.data.Name}</h1>
                            <p className="designation">{props.data.Designation}</p>
                            <div className="call"><a href={'tel:' + props.data.Phone}> <img src={Phone} /> {uknumberformat(props.data.Phone)}</a></div> <div className="call"><ModelForm page="teamdetail" Name={props.data?.Name} mailid={props.data?.Email} /></div>
                          </div>
                          <div className="button-groups-property-banner d-none d-lg-flex">
                            <Link to={VALUATION_PAGE_URL.alias} className="btn btn-primary">Book a Valuation</Link>
                            <AskusaQuestion />
                          </div>
                          <div className="team-vi-blk d-lg-none">
                            {showVideomen && <div onClick={(e) => { closePlaymen(e) }} className="close-iframe-btn"></div>}
                            <div className={showVideomen ? "img-hover-zoom show-video-team" : "img-hover-zoom"}>
                              {showVideomen &&
                                <YouTube
                                  video={videoIdmen}
                                  autoplay
                                  showRelatedVideos={false}
                                  showInfo={false}
                                  annotations={false}
                                  onEnd={e => { closePlaymen(e) }}
                                  modestBranding={1}
                                  onPlaying={trackerVideomen(props?.data?.Name)}

                                />
                              }
                              {/* <picture>
                                <source media="(min-width:992px)" srcSet={props.data.Image?.url} />
                                <source media="(min-width:768px)" srcSet={props.data.Image?.url} />
                                <img src={props.data.Image?.url} alt={props.data.Image?.alternativeText} />
                            </picture> */}
                              
                              {props.data?.Image && props.data?.Image?.url ?
                              <ImageModule ImageSrc={props.data?.Image} attr={{ alt: props?.data?.Name }} ggfx_results={props.data?.ggfx_results} imagename="team.Image.detail" strapi_id={props.data.id} />

                              // <ImageTransform imagesources={props.data?.Image?.url} renderer="srcSet" imagename='team.Image.detail'
                              //   attr={{ alt: props.data.Image?.alternativeText, className: '' }}
                              //   imagetransformresult={processedImages} id={props.data.id} >

                              // </ImageTransform>
                              : <NoImage/>}
                              {props.data?.Video_Url && !showVideomen &&
                                <span
                                  onClick={(e) => {
                                    playVideomen(props.data?.Video_Url);
                                  }}
                                  className="video-btn"><i class="icon-video"></i></span>
                              }
                            </div>
                          </div>
                          <div className="qes-ans">
                            <ReactMarkdown source={props?.data?.Content} allowDangerousHtml />
                          </div>
                        </motion.div>
                      </ScrollAnimation>
                    </Col>
                    <Col lg={{ size: 5, offset: 1 }} className="d-lg-block d-none">
                      <div className=" sticky-blk">
                        <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
                          <motion.div variants={contentVariants}>
                            <div className="team-vi-blk">
                              {showVideomen && <div onClick={(e) => { closePlaymen(e) }} className="close-iframe-btn"></div>}
                              <div className={showVideomen ? "img-hover-zoom show-video-team" : "img-hover-zoom"}>
                                {showVideomen &&
                                  <YouTube
                                    video={videoIdmen}
                                    autoplay
                                    showRelatedVideos={false}
                                    showInfo={false}
                                    annotations={false}
                                    onEnd={e => { closePlaymen(e) }}
                                    modestBranding={1}
                                    onPlaying={trackerVideomen(props?.data?.Name)}

                                  />
                                }
                                {/* <picture>
                                <source media="(min-width:992px)" srcSet={props.data.Image?.url} />
                                <source media="(min-width:768px)" srcSet={props.data.Image?.url} />
                                <img src={props.data.Image?.url} alt={props.data.Image?.alternativeText} />
                            </picture> */}
                                {props.data?.Image && props.data?.Image?.url ?
                                <ImageModule ImageSrc={props.data?.Image} attr={{ alt: props?.data?.Name }} ggfx_results={props.data?.ggfx_results} imagename="team.Image.detail" strapi_id={props.data.id} />

                                // <ImageTransform imagesources={props.data?.Image?.url} renderer="srcSet" imagename='team.Image.detail'
                                //   attr={{ alt: props.data.Image?.alternativeText, className: '' }}
                                //   imagetransformresult={processedImages} id={props.data.id} >
                                // </ImageTransform>
                                : <NoImage/>}
                                {props.data?.Video_Url && !showVideomen &&
                                  <span
                                    onClick={(e) => {
                                      playVideomen(props.data?.Video_Url);
                                    }}
                                    className="video-btn"><i class="icon-video"></i></span>
                                }
                              </div>
                            </div>
                          </motion.div>
                        </ScrollAnimation>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              {props.data.Request_A_Viewing_CTA &&
                <RequestAViwing Homepage={false} Request_A_Viewing_CTA={props.data.Request_A_Viewing_CTA} />
              }
            </React.Fragment>
          </motion.section>
        )
      }}
    </InView>
  )
}

export default TeamDetailsBanner;