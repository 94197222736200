import React from "react"
import NoImage from "../../images/no-image.png"

const noImage = () => {
  return (
    <picture>
      <img src={NoImage} alt="" />
    </picture>
  )
}

export default noImage