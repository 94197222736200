import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal"
import AskusQuestion from "@Components/forms/AskusQuestion"
import Email from "../../images/email.svg"
const AskusaQuestion = (props) => {
    const [show, setShow] = useState(false);
    const handleOpen = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
    <>
    <a onClick={handleOpen} className="btn btn-outline"> Ask us a Question</a>
    <Modal
        id="share_modal_mobile"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
          dialogClassName="modal-90w modal-form"
      >
        <Modal.Header closeButton className="contact-close-btn memberPopup">
          <Modal.Title className="w-100"><h6 className="text-center content-primary-color mb-0">Ask us a Question</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <AskusQuestion mailId={props.mailid}  />

        </Modal.Body>
      </Modal>  
      </>
    )
}

export default AskusaQuestion